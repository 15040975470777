import React from "react"
import Fade from "react-reveal/Fade"
import { CAREER_LIST } from "../../utils/constants"
const CareerRole = ({ title, description, url }) => (
  <a
    href={url}
    target="_blank"
    className="m-5 p-5 bg-lightblack rounded-xl border cursor-pointer hover:border-purple text-left"
  >
    <h2 className="text-3xl font-bold">{title}</h2>
    <p className="mt-10 opacity-50">{description}</p>
  </a>
)

const CareersRoles = () => {
  debugger
  return (
    <div className="max-w-7xl mx-auto flex flex-col items-center text-white mt-20 font-poppins">
      <h2 className="text-sm font-bold opacity-50">Join our team</h2>
      <h1 className="text-6xl font-bold mt-3">View open roles</h1>
      <Fade bottom cascade>
        <div className="grid grid-cols-2 gap-6 mt-10">
          {CAREER_LIST?.map((career, index) => (
            <CareerRole
              key={index}
              title={career.title}
              url={career.url}
              description={career.description}
            />
          ))}
        </div>
      </Fade>
    </div>
  )
}

export default CareersRoles
